.MuiTreeItem-iconContainer {
  font-weight: 600;
}
.MuiTreeItem-group {
  margin-left: 18px;
  padding-left: 20px;
  border-left: 1px dashed rgba(0, 0, 0, 0.4);
}
.MuiTypography-body1 {
  font-weight: 600;
}
